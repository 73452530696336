<template> 
  <div class="body">
    <div>
      <p>View my GitHub <a href="https://github.com/scoolerh" target="_blank">here</a>.</p>
      <br /> <br />
      <p>You can view my resume below:</p>
      <iframe 
        src="https://docs.google.com/document/d/1fstbLd4zbbX3n2kYHDZonAfGVAsUlBJRlId-Esf_cH0/preview"
        width="100%"
        height="1000px"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<style> 
.body {
  width: 80%;
}
iframe {
  border-radius: 20px;
}
</style>
