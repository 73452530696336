<template>
  <div class="body">
    <p>View my Goodreads page <a href="https://www.goodreads.com/user/show/159382572-hannah-scooler" target="_blank">here</a>.</p>
    <br /><br />
    <p>View my reading spreadsheet below, where I keep track of the number of pages I read per day and the books I have finished this year:</p>
    <iframe src="https://docs.google.com/spreadsheets/d/1RKoTTu7cqxKesELZOfISAc0uQJp7TXwz3Y-XMOw9c2I/preview"
      width="100%"
      height="600px">
    </iframe>
    <p>View my spreadsheet from <a href="https://docs.google.com/spreadsheets/u/1/d/1-f0Nulxs1oqzj1Wx2niizNMI5fTqCUN8-cudzkoqG48/preview" target="_blank">last year</a> or from <a href="https://docs.google.com/spreadsheets/u/1/d/1XoBT-Me-WhkPdAhl31X60oB0xBCLpcngPqLSMxxfr34/preview" target="_blank">2022</a></p>
  </div>
  
</template>

<style>
.body {
  justify-content: center;
  width: 100%;
}
img {
  height: auto;
  margin-left: 5%;
  width: 30%;
  max-width: 100%;
  border-radius: 20px;
}
</style>
